import { Image, Nav } from 'react-bootstrap';
import './App.css';
import Sidebar from './components/sidebar/sidebar.js';
import Deck from './components/deck/deck.js';
import React from 'react';
import Api from './api.js';
import Grid from './components/deck/landingpage/grid.js';
import LandingPage from './components/deck/landingpage/landingPage.js';
import { Link } from 'react-router-dom';
import Contact from './components/sidebar/contact/contact.js';
import Share from "./components/deck/common/share.js";
import MenuOpen from "./components/deck/common/icons/menuopenicon";
import Login from "./components/sidebar/account/login.js";
import MenuClose from "./components/deck/common/icons/menucloseicon.js";
import TMFooter from './components/deck/common/footer.js';

class App extends React.Component {

  constructor(props) {
    super(props);
    const token = localStorage.getItem("tm-user-token");
    this.state = {
      selectedArticleIds: [],
      withinExhibitId: null,
      selectedCategory: [],
      breadcrumb: "",
      exhibits: [],
      sidebarOpen: false,
      user: token ? { authToken: token }: null,
      link: { text: null },
      search: props.search,
      noScroll: null,
      menu: [],
      anchor: null,
      layout: "grid",
      url: props.search ? props.match.params.segment : null
    }
    this.api = new Api();
    this.masterEx = null;
    this.masterExId = null;
    this.exConfig = {};
    this.queue = [];
    this.conf = {};
    this.env = this.api.getEnv();
    this.domain = this.api.getDomain();
    this.subdomain = this.api.getSubdomain();
    document.documentElement.style.setProperty('--accent-color', this.env.accent);
    document.documentElement.style.setProperty('--highlight-color', this.env.highlight);
    document.documentElement.getElementsByTagName("head")[0].children.item(1).href = window.location.href +
      process.env.PUBLIC_URL + this.domain + "/" + this.subdomain + "/favicon.svg?123"
    this.ref = React.createRef();
    this.hostTitleMap = {
      "www.townmaking": "Townmaking",
      "nextcommons.townmaking": "NextCommons",
      "digitalsociety.townmaking": "Digital Society",
      "narratives.townmaking": "Narratives",
      "rae.townmaking": "Regenerative Asset Engineering",
      "www.embodiedmaking": "Embodied Making",
      "stage.embodiedmaking": "Embodied Making",
      "www.product-foundry": "Product Foundry",
      "stage.product-foundry": "Product Foundry",
      "stage.localhost": "Townmaking",
      "nc.localhost": "NextCommons",
      "narratives.localhost": "Narratives",
      "stagedigitalsociety.localhost": "Digital Society",
      "claysites.herokuapp": "Narratives"
    }
    document.documentElement.getElementsByTagName("head")[0].getElementsByTagName("title")[0].innerHTML =
      this.hostTitleMap[this.subdomain + "." + this.domain]
  }

  componentDidMount = () => {
    this.api.getExhibitConfig().then((res) => {
      const c = res.data[this.domain];
      if (this.subdomain && this.subdomain !== '' && c[this.subdomain]) {
        this.exConfig = c[this.subdomain].exhibition;
      } else {
        this.exConfig = c.exhibition;
      }
      this.excluded = this.exConfig.landingPage;
      this.fetchTitles(this.state.user ? this.state.user.authToken : null);
    }).catch(e => console.error("NodeJS Server not available", e));
    if (this.state.anchor === null) {
      this.setState({ anchor: this.ref });
    }
  }

  loggedIn = (authToken, user) => {
    let u = Object.assign({}, user);
    u.authToken = authToken;
    this.setState({ user: u });
    this.fetchTitles(authToken);
    this.goHome();
  }

  loggedOut = () => {
    if (this.state.user) {
      this.setState({ user: null });
      this.goHome();
    }
    this.setState({ search: false })
    this.fetchTitles();
  }

  fetchTitles = (authToken) => {
    // Menu items are publications in Clay under that organization
    this.api.getExhibits(authToken)
      .then((responseAuth) => {
        this.api.getExhibits()
          .then(response => {
            let menus = [];
            menus.push(...response.data.menus);
            menus.push(...responseAuth.data.menus);
            menus = menus.filter((v, i, a) => a.findIndex(v2 => (v.exhibitionId === v2.exhibitionId)) === i);
            if (this.exConfig.master) {
              this.masterExId = menus.filter(m => m.name === this.exConfig.master)[0].exhibitionId;
              if (this.masterExId) {
                let sdtitles;
                this.fetchExhibit(this.masterExId).then(() => {
                  let domtitles = this.masterEx.rootNode.children[0].children.filter((ch) => {
                    return ch.element.classifications.includes(this.domain);
                  });
                  console.log("domtitles", domtitles);
                  if (domtitles.length > 0) {
                    sdtitles = domtitles.filter((t) => {
                      return t.children[0].element.content.data.value.name === this.subdomain;
                    });
                    if (sdtitles.length < 1) {
                      sdtitles = domtitles;
                    }
                  } else {
                    console.error("No config for domain " + this.domain);
                  }
                  this.fetchIncludedTitles(sdtitles);
                  this.exConfig = Object.assign(this.exConfig, this.conf);
                  this.exConfig.includesPrivate = this.exConfig.includesPrivate || "";

                  let pubArray = this.state.user ?
                    this.exConfig.includesPrivate.toLowerCase().split("|") :
                    this.exConfig.includes.toLowerCase().split("|");
                  let sisterOrgIds = [], sisterExPromises;

                  pubArray.forEach((pub, i) => {
                    if (pub.indexOf("@") > -1) {
                      sisterOrgIds.push(pub.split("@")[1])
                    }
                  })
                  if (sisterOrgIds.length) {
                    // this.api.getOrganizations().then(res => {
                    sisterExPromises = sisterOrgIds.map(orgId => this.api.getSisterExhibits(authToken, orgId))
                    Promise.all(sisterExPromises).then(res2 => {
                      const sisterMenus = res2.map(r => r.data.menus).flat();
                      this.processExhibits([...menus, ...sisterMenus], pubArray, authToken);
                    })
                  } else {
                    this.processExhibits(menus, pubArray, authToken)
                  }

                })
              }
            }
          })


      })
      .catch((err) => console.log(err));
  }

  processExhibits = (menus, pubArray, authToken) => {
    const filtered = menus.map(
      (m) => {
        const i = pubArray.findIndex((p => p.indexOf(m.name.toLowerCase()) > -1));
        if (
          i > -1 &&
          (!this.excluded || this.excluded.toLowerCase() !== m.name.toLowerCase())) {
          if (pubArray[i].indexOf("@") > -1) {
            if (pubArray[i].indexOf(m.orgId) > -1) {
              m.show = true;
              return m;
            }
          } else {
            m.show = true;
            return m;
          }

        } else {
          return m;
        }
      });
    const sorted = filtered.filter(f => !!f).sort((a, b) => {
      const idxA = pubArray.findIndex((p => p.indexOf(a.name.toLowerCase()) > -1));
      const idxB = pubArray.findIndex((p => p.indexOf(b.name.toLowerCase()) > -1));

      if (idxA > idxB) return 1; else return -1;
    })

    const m = sorted.map((e) => {
      return this.fetchExhibit(e.exhibitionId, authToken)
    })
    Promise.all(m).then(res => {
      const exhibits = { ...this.state.exhibits };
      res.filter(r => r).forEach(r => {
        exhibits[r.rootNode.element.id.data.exhibitionId] = r
      })
      this.setState({ exhibits: exhibits, menu: sorted });
      const landingPageId = sorted.filter(m => m.name === this.exConfig.landingPage)[0];
      landingPageId && this.setState({ landingPage: this.state.exhibits[landingPageId.exhibitionId] });
      const constellationsId = sorted.filter(m => m.name === this.exConfig.patterns)[0];
      constellationsId && this.setState({
        constellations:
          this.state.exhibits[constellationsId.exhibitionId].rootNode.children[0].children.filter((c) => {
            return c.element.classifications.includes("Constellation");
          })
      });
    })

  }

  fetchIncludedTitles = (children) => {
    this.queue.push(...children);
    while (this.queue.length > 0) {
      const child = this.queue.shift();
      if (child.element.content.type === "Value" &&
        child.element.content.data.attribute === "characteristic") {
        this.conf[child.element.content.data.value.name] = child.element.content.data.value.value;
      }
      this.fetchIncludedTitles(child.children);
    }
  }

  fetchExhibit = (exhibitionId, authToken, orgId) => {
    return this.api.getExhibit(exhibitionId, authToken, orgId)
      .then((response) => {
        const eId = response.config.url.split("/")[2];
        if (this.masterExId === eId) {
          this.masterEx = response.data;
        } else {
          return response.data;
        }
      })
  }

  toggleSidebar = () => {
    this.setState({ sidebarOpen: !this.state.sidebarOpen })
  }

  selectArticle = (cIds, eId, noScroll) => {
    this.setState({
      selectedArticleIds: [...cIds],
      withinExhibitId: eId,
      link: { text: null },
      noScroll: noScroll,
      selectedExhibitId: null,
      selectedCategory: [],
      breadcrumb: null,
      selectedToc: null,
      layout: "deck",
      url: null
    });
    if (cIds.length > 0) {
      const ex = this.state.exhibits[eId];
      this.setState({
        exhibitTitle: ex ? ex.rootNode.element.content.data.value : ""
      });
      const gotoArticle = cIds[cIds.length - 1];
      setTimeout(() => {
        if (!noScroll)
          document.getElementById(gotoArticle)?.scrollIntoView({ behavior: "smooth" })
      }, 600);
    } else { // user deselected all
      this.setState({ withinExhibitId: null, exhibitTitle: null });
    }
  }

  selectExhibit = (eId) => {
    window.history.pushState(null, window.document.title, window.location.origin, "/");
    this.setState({
      selectedExhibitId: eId,
      withinExhibitId: null,
      selectedArticleIds: [],
      selectedCategory: [],
      selectedToc: null,
      layout: "grid",
      breadcrumb: null,
      url: null
    })
  }

  selectCategory = (cIdArray, eId, breadcrumb, toc, root, url) => {
    const subCats = toc.children.filter(child => child.item.type === "Classification");
    if (subCats.length > 0) {
      this.setState({
        selectedCategory: root || this.state.exhibits[eId],
        withinExhibitId: eId,
        selectedArticleIds: [],
        selectedExhibitId: null,
        breadcrumb: breadcrumb,
        selectedToc: toc,
        layout: "grid",
        url: url
      })
    } else {
      this.selectSimpleCategory(cIdArray, eId, breadcrumb, toc, url);
    }
    window.history.pushState(null, window.document.title, window.location.origin + "/search" + url);
  }

  selectSimpleCategory = (cIdArray, eId, breadcrumb, toc, url) => {
    if (cIdArray.length < 4) {
      this.setState({
        selectedCategory: [],
        withinExhibitId: eId,
        selectedArticleIds: cIdArray,
        selectedExhibitId: null,
        breadcrumb: breadcrumb,
        selectedToc: toc,
        layout: "deck",
        url: url
      })
    } else {
      this.setState({
        selectedCategory: cIdArray,
        withinExhibitId: eId,
        selectedArticleIds: [],
        selectedExhibitId: null,
        breadcrumb: breadcrumb,
        selectedToc: toc,
        layout: "grid",
        url: url
      })
    }


  }

  goHome = () => {
    this.props.history.push("/");
    const els = document.getElementsByClassName("nav-link");
    [].forEach.call(els, function (el) { el.style.textDecoration = "none" });
    const els2 = document.getElementsByClassName("menu-title");
    [].forEach.call(els2, function (el) { el.style.textDecoration = "none" });
    this.setState({
      withinExhibitId: null,
      selectedArticleIds: [],
      selectedCategory: [],
      selectedExhibitId: null,
      breadcrumb: null,
      exhibitTitle: null,
      search: false,
      selectedToc: null,
      layout: "grid",
      url: null
    });
  }

  goBack = () => {
    const loc = this.props.history.location.pathname;
    const search = loc.substring(loc.indexOf("/search/") + 8);
    const pages = search.split("/");
    if (pages.length) {
      pages.pop();
      if (pages.length) {
        this.props.history.push("/search/" + pages.join("/"));
      } else {
        this.props.history.push("/");
      }
    } else {
      this.props.history.push("/");
    }
    const els = document.getElementsByClassName("nav-link");
    [].forEach.call(els, function (el) { el.style.textDecoration = "none" });
    const els2 = document.getElementsByClassName("menu-title");
    [].forEach.call(els2, function (el) { el.style.textDecoration = "none" });
    this.setState({
      withinExhibitId: null,
      selectedArticleIds: [],
      selectedCategory: [],
      selectedExhibitId: null,
      breadcrumb: null,
      exhibitTitle: null,
      search: false,
      selectedToc: null,
      layout: "grid",
      url: null
    });
  }

  closeCard = (cId, eId) => {
    if (this.state.selectedArticleIds.length === 1) {
      this.goBack();
    } else {
      var cIds = [...this.state.selectedArticleIds];
      cIds.splice(this.state.selectedArticleIds.indexOf(cId), 1);
      this.setState({ selectedArticleIds: cIds });
    }
  }

  clickLink = (link, keepOpen = false) => {
    this.setState({
      link: {
        text: link,
        keepOpen: keepOpen
      }
    })
  }

  render() {
    const c1 = this.state.sidebarOpen ? "sidebar-open" : "sidebar-closed";
    const c2 = this.state.selectedArticleIds.length === 0 ? "landing-page-container" : "";
    const gridRoots = [];
    const siteLanding = !this.state.withinExhibitId && !this.state.selectedExhibitId && !this.state.search;
    const deckLayout = this.state.exhibits[this.state.withinExhibitId] && this.state.selectedArticleIds.length > 0;
    if (!this.state.landingPage || this.exConfig.generatedLanding) {
      if (siteLanding) {
        this.state.menu.forEach(pub => {
          if (pub.show) {
            gridRoots.push(this.state.exhibits[pub.exhibitionId]);
          }
        })
      } else if (this.state.selectedExhibitId) {
        gridRoots.push(this.state.exhibits[this.state.selectedExhibitId]);
      } else if (this.state.selectedCategory.length > 0) {
        const ex = this.state.exhibits[this.state.withinExhibitId];
        gridRoots.push(ex);
      } else if (this.state.selectedToc) {
        const ex = this.state.exhibits[this.state.withinExhibitId];
        gridRoots.push(ex);
      }
    }

    return (
      <div className={`container-fluid p-0 page ${c1} ${c2}`}>

        <Nav className={`col-xs-1 nav-spine`}>
          <Nav.Item>
            <Login
              // updateState={this.updateLoginState}
              isOpen={this.state.loginOpen}
              loggedIn={this.loggedIn}
              loggedOut={this.loggedOut}
              match={this.props.match}
            />
          </Nav.Item>
          <Nav.Item>
            <Nav.Link className="burger active" onClick={this.toggleSidebar}>
              {this.state.sidebarOpen ? <MenuOpen size="32" /> : <MenuClose size="32" />}
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Item ref={this.boardRef}>
              <Share
                link={window.location.origin}
                anchor={this.state.anchor}
                id="sidebar-share"
                updateState={this.updateShareState}
                isOpen={this.state.shareOpen}
                placement="bottom-start"
              />
            </Nav.Item>
            <Nav.Item>
              <Contact
                updateState={this.updateContactState}
                isOpen={this.state.contactOpen}
              />
            </Nav.Item>
          </Nav.Item>
        </Nav>
        <div className="main">
          <div className="brand-container">
            <Link to="/">
              <Image src={`${window.location.origin + "/" + this.domain + "/" + this.subdomain}/brand20caab.svg?123`} onClick={this.goHome} className="brand" />
            </Link>
          </div>
          <div className="center-stage">
            <Sidebar
              selectArticle={this.selectArticle}
              selectCategory={this.selectCategory}
              selectExhibit={this.selectExhibit}
              goHome={this.goHome}
              minimal={!this.state.sidebarOpen}
              selectedArticleIds={this.state.selectedArticleIds}
              withinExhibitId={this.state.withinExhibitId}
              link={this.state.link}
              search={this.props.search}
              match={this.props.match}
              history={this.props.history}
              loggedIn={this.loggedIn}
              loggedOut={this.loggedOut}
              user={this.state.user}
              env={this.env}
              subdomain={this.subdomain}
              menu={this.state.menu}
              toggleSidebarStateClass={this.toggleSidebar}
            />
            <div className="content-area">
              <div className="reader-area">
                {(!this.state.search && siteLanding && this.state.landingPage) &&
                  <LandingPage
                    landingPage={this.state.landingPage}
                    clickLink={this.clickLink}
                    clickShare={this.clickShare}
                    env={this.env}
                  />}
                {(this.state.layout === "grid") && <Grid
                  pubRoots={gridRoots}
                  selectedCategory={this.state.selectedCategory}
                  selectArticle={this.selectArticle}
                  selectCategory={this.selectCategory}
                  breadcrumb={this.state.breadcrumb}
                  selectedToc={this.state.selectedToc}
                  url={this.state.url}
                />}
                {(this.state.layout === "deck") &&
                  <Deck
                    selectedArticleIds={[...this.state.selectedArticleIds]}
                    withinExhibit={this.state.exhibits[this.state.withinExhibitId]}
                    closeCard={this.closeCard}
                    clickLink={this.clickLink}
                    constellations={this.state.constellations}
                    env={this.env}
                    noScroll={this.state.noScroll}
                  />}
              </div>
              {!deckLayout && <TMFooter />}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default App;
